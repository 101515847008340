import React, { useEffect, useState, useRef } from 'react';
import { Button, Box, Divider, Backdrop, CircularProgress, Badge } from '@material-ui/core';
import moment from 'moment';

import CustomUploadButton from '../../../../components/CustomFormFields/CustomUploadButton';
import FileViewerButton2 from '../../../../components/FileViewerButton2';
import CustomModal from '../../../../components/CustomModal';
import CustomFileDropzone from '../../../../components/CustomFileDropzone';

const CompanyDocumentsGroupItem = (props) => {
  const { title, value = [], onAdd, onRemove, disabled } = props;

  const defaultUploadForm = {
    show: false,
    data: null,
  };
  const [uploadForm, setUploadForm] = useState({
    show: false,
  });
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const ref = useRef();
  let files = value?.length > 0 ? value : [];

  const handleAddDocument = async (e) => {
    console.log('handleAddDocument', e);
    if (e?.target?.files?.length > 0) {
      let newFiles = Array.from(e?.target?.files);

      newFiles = newFiles.map((anItem) => {
        return {
          file: anItem,
          isNew: true,
        };
      });

      let newValue = [...files, ...newFiles];
      newValue = newValue.map((anItem, index) => {
        return {
          ...anItem,
          rowId: index,
        };
      });
      if (onAdd) {
        onAdd(newValue, newFiles);
      }
      if (ref?.current?.value) {
        ref.current.value = '';
      }
    }
  };

  const handleRemoveDocument = (selectedItem) => {
    // if (window.confirm('Confirm Remove Document?')) {
    let newValue = files;
    if (selectedItem?.isNew) {
      newValue = files.filter((anItem) => {
        return anItem?.rowId != selectedItem?.rowId;
        // let result = true;
        // if (selectedItem?.document_id) {
        //   if (selectedItem.document_id == anItem.document_id) {
        //     result = false;
        //   }
        // } else {
        //   if (selectedItem?.rowId && selectedItem.rowId == anItem.rowId) {
        //     result = false;
        //   }
        // }
        // return result;
      });
      newValue = newValue.map((anItem, index) => {
        return {
          ...anItem,
          rowId: index,
        };
      });
    } else {
      newValue = files.map((anItem, index) => {
        if (selectedItem?.rowId == anItem?.rowId) {
          return {
            ...anItem,
            isDeleted: true,
          };
        }
        return anItem;
      });
    }
    console.log('newValue', newValue);
    if (onRemove) {
      onRemove(newValue, selectedItem);
    }
    // }
  };

  const handleSelectDocuments = async (e) => {
    if (e?.target?.files?.length > 0) {
      let newFiles = Array.from(e?.target?.files);

      newFiles = newFiles.map((anItem) => {
        return {
          file: anItem,
          name: anItem?.name,
          size: anItem?.size,
          isNew: true,
        };
      });

      let newValue = [...newFiles];
      newValue = newValue.map((anItem, index) => {
        return {
          ...anItem,
          rowId: index,
        };
      });
      setSelectedDocuments(newValue);
    } else {
      setSelectedDocuments([]);
    }
  };

  const handleUploadDocuments = async () => {
    // if (selectedDocuments?.length > 0) {
    //   onAdd(selectedDocuments);
    // }
    if (selectedDocuments?.length > 0) {
      let newFiles = Array.from(selectedDocuments);

      newFiles = newFiles.map((anItem) => {
        return {
          file: anItem,
          isNew: true,
        };
      });

      let newValue = [...files, ...newFiles];
      newValue = newValue.map((anItem, index) => {
        return {
          ...anItem,
          rowId: index,
        };
      });
      if (onAdd) {
        onAdd(newValue, newFiles);
      }
      if (ref?.current?.value) {
        ref.current.value = '';
      }

      setSelectedDocuments([]);
      setUploadForm(defaultUploadForm);
    }
  };

  let canUpload = selectedDocuments?.length > 0;
  console.log('files', files);
  return (
    <Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Box>
          <b>{title}</b>
        </Box>
        <Box ml="auto">
          {/* <Button
            component="label"
            variant="contained"
            color="primary"
            size="small"
            disabled={disabled}
            onChange={handleAddDocument}
          >
            Upload Document
            <input ref={ref} type="file" multiple hidden />
          </Button> */}
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={disabled}
            onClick={() => {
              setUploadForm((prev) => {
                return {
                  ...prev,
                  show: true,
                };
              });
            }}
          >
            Upload Document
          </Button>
        </Box>
      </Box>
      {files?.length > 0 ? (
        <Box>
          {files.map((anItem, index) => {
            const {
              uploaded_date,
              signed_document_url,
              file_name,
              file,
              rowId,
              isDeleted,
            } = anItem;
            // let uploadedDate = '-';
            // try {
            //   uploadedDate = moment(uploaded_date).format(`DD/MM/yyyy hh:mm:ssa`);
            // } catch (error) {}
            let isNewFile = signed_document_url ? false : file ? true : null;
            return (
              <Box key={index} display={'flex'} alignItems="center" mb={1}>
                <Box flexGrow={1} display="flex" alignItems="center">
                  <Box mr={2}>{index + 1}.</Box>
                  {signed_document_url ? (
                    <FileViewerButton2
                      srcType="url"
                      src={signed_document_url}
                      size="small"
                      document={anItem}
                    >
                      View
                    </FileViewerButton2>
                  ) : file ? (
                    <FileViewerButton2 srcType="file" src={file} size="small">
                      View
                    </FileViewerButton2>
                  ) : null}
                  {/* <Box>{file?.name}</Box> */}
                  {isNewFile ? (
                    <Badge badgeContent={'New'} color="primary">
                      <Box>{file?.name}</Box>
                    </Badge>
                  ) : isDeleted ? (
                    <Badge badgeContent={'Remove'} color="secondary">
                      <Box>{file_name || '-'}</Box>
                    </Badge>
                  ) : (
                    <Box>{file_name || '-'}</Box>
                  )}
                  {/* {file && (
                    <FileViewerButton2 srcType="file" src={file} size="small">
                      View
                    </FileViewerButton2>
                  )} */}

                  {/* {signed_document_url && (
                    <Box>
                      <CustomUploadButton
                        previewLink={signed_document_url}
                        previewLabel={'View'}
                        hideUpload={true}
                        size="small"
                      />
                    </Box>
                  )} */}
                </Box>
                <Button
                  disabled={disabled}
                  variant="contained"
                  size="small"
                  onClick={() => {
                    handleRemoveDocument(anItem);
                  }}
                >
                  Remove
                </Button>
              </Box>
            );
          })}
        </Box>
      ) : (
        'No Document'
      )}

      <CustomModal
        show={uploadForm.show}
        onHide={() => {
          setUploadForm(defaultUploadForm);
        }}
        scrollable={false}
        title="Upload"
      >
        <Box mb={2}>
          <CustomFileDropzone
            multiple={true}
            files={selectedDocuments?.length > 0 ? selectedDocuments : []}
            setFiles={(files = [], removeFile = false) => {
              let result = [];
              let newValue = files?.length > 0 ? files : [];
              result = removeFile
                ? newValue
                : [...(selectedDocuments?.length > 0 ? selectedDocuments : []), ...newValue];
              console.log('CustomFileDropzone', {
                files,
                result,
              });
              handleSelectDocuments({ target: { files: result } });
            }}
            //  error={(manualTouch || meta.touched) && !!meta.error}
            //  helperText={meta.touched && meta.error ? meta.error : props.helperText || ''}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            disabled={!canUpload}
            onClick={handleUploadDocuments}
          >
            OK
          </Button>
        </Box>
      </CustomModal>
    </Box>
  );
};

export default CompanyDocumentsGroupItem;
